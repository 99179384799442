import { Suspense } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Services from "../components/Services";
import ContactForm from "../components/ContactForm";
import Reviews from "../components/Reviews";
import LoadingFallback from "../components/LoadingFallback";
import Store from "../components/Store";
import AnimatedSection from "../components/AnimatedSection";

const MainPage = () => (
  <>
    <NavBar />
    <Suspense fallback={<LoadingFallback />}>
      <AnimatedSection animation="fadeInUp">
        <Hero />
      </AnimatedSection>

      <AnimatedSection animation="fadeInLeft" delay={0.2}>
        <Services />
      </AnimatedSection>

      <AnimatedSection animation="fadeInRight" delay={0.3}>
        <ContactForm />
      </AnimatedSection>

      <AnimatedSection animation="fadeInUp" delay={0.2}>
        <Store />
      </AnimatedSection>

      <AnimatedSection animation="zoomIn" delay={0.3}>
        <Reviews />
      </AnimatedSection>
    </Suspense>
    <Footer />
  </>
);

export default MainPage;
