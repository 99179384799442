import {
  FaInstagram,
  FaWhatsapp,
  FaFacebook,
  FaClock,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa";
import {
  BusinessHours,
  LocationMap,
  SocialMedia,
  InfoSection,
  Copyright,
  FooterCss,
  Creator,
} from "./styles";

const businessHours = [
  { day: "Segunda-feira", hours: "09:00 - 18:00" },
  { day: "Terça-feira", hours: "09:00 - 18:00" },
  { day: "Quarta-feira", hours: "09:00 - 18:00" },
  { day: "Quinta-feira", hours: "09:00 - 18:00" },
  { day: "Sexta-feira", hours: "09:00 - 18:00" },
  { day: "Sábado", hours: "09:00 - 13:00" },
  { day: "Domingo", hours: "Fechado" },
];

const Footer = () => (
  <FooterCss id="contact">
    <div className="container">
      {/* Informações Gerais */}
      <InfoSection>
        {/* Mapa de Localização */}
        <LocationMap>
          <h3>
            <FaMapMarkerAlt aria-hidden="true" /> Nossa Localização
          </h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d561.8033253213672!2d-47.42022705230736!3d-20.404004795042297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1spt-BR!2sbr!4v1732073002061!5m2!1spt-BR!2sbr"
            title="Mapa de Localização"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
          <p>Rua: Ulisses de Paula Garcia, 19 - Cristais Paulista</p>
        </LocationMap>

        {/* Horários de Funcionamento */}
        <BusinessHours>
          <h3>
            <FaClock aria-hidden="true" /> Horário de Funcionamento
          </h3>
          <ul>
            {businessHours.map((schedule, index) => (
              <li key={index}>
                <span>{schedule.day}:</span>
                <span className={schedule.hours === "Fechado" ? "closed" : ""}>
                  {schedule.hours}
                </span>
              </li>
            ))}
          </ul>
        </BusinessHours>

        {/* Redes Sociais */}
        <SocialMedia>
          <h3>Redes Sociais</h3>
          <div className="social-links">
            <a
              href="https://www.instagram.com/engenhosocell/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visite nosso Instagram"
            >
              <FaInstagram aria-hidden="true" /> Instagram
            </a>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visite nosso Facebook"
            >
              <FaFacebook aria-hidden="true" /> Facebook
            </a>
            <a
              href="https://wa.me/16981451428"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Envie mensagem no WhatsApp"
            >
              <FaWhatsapp aria-hidden="true" /> WhatsApp
            </a>
          </div>

          {/* Contatos */}
          <div className="contact-info">
            <a
              href="tel:+5516981451428"
              aria-label="Ligue para +55 (16) 98145-1428"
            >
              <FaPhone aria-hidden="true" />
              <p>+55 (16) 98145-1428</p>
            </a>
            <a
              href="mailto:engenhosocel@gmail.com"
              aria-label="Envie um email para engenhosocel@gmail.com"
            >
              <FaEnvelope aria-hidden="true" />
              <p>engenhosocel@gmail.com</p>
            </a>
          </div>
        </SocialMedia>
      </InfoSection>
    </div>

    {/* Direitos Autorais */}
    <Copyright>
      <div className="copyright">
        <p>&copy; 2025 Engenhoso Cell. Todos os direitos reservados.</p>
        <Creator>
          <a
            href="https://rodrigao-sp.github.io/portfolio/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visite o portfólio do desenvolvedor"
          >
            Desenvolvido por Rodrigo S. Pires
            <span className="dev-title">Full Stack Developer</span>
          </a>
        </Creator>
      </div>
    </Copyright>
  </FooterCss>
);

export default Footer;
