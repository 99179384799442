import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";
import { pulse, shine } from "../../styles/animations";

export const FooterCss = styled.footer`
  width: 100%;
  background-color: ${Cores.primary};
  color: ${Cores.textDark};
  padding: 60px 0 0;
  position: relative;
  overflow: hidden;
`;

export const InfoSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;

    iframe {
      width: 100%;
    }
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    color: ${Cores.textDark};
    background: linear-gradient(90deg, ${Cores.textDark}, #666, ${Cores.textDark});
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 3s linear infinite;
  }
`;

export const BusinessHours = styled.div`
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .closed {
        color: #ff0000;
        font-weight: bold;
      }
    }
  }
`;

export const LocationMap = styled.div`
  p {
    margin-top: 16px;
    font-size: 1rem;
    color: ${Cores.textDark};
  }

  iframe {
    border-radius: 8px;
    width: 100%;
    height: 250px;
  }
`;

export const SocialMedia = styled.div`
  .social-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 16px;

    a {
      color: ${Cores.textDark};
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 1rem;
      transition: all 0.3s ease;
      padding: 8px;
      border-radius: 8px;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
        transform: translateX(8px);
        animation: ${pulse} 1s ease-in-out;
      }
    }
  }

  .contact-info {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${Cores.textDark};
      font-size: 1rem;
      transition: all 0.3s ease;
      padding: 8px;
      border-radius: 8px;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
        transform: translateX(8px);
        animation: ${pulse} 1s ease-in-out;
      }
    }
  }
`;

export const Copyright = styled.div`
  text-align: center;
  padding: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.3), transparent);
    animation: ${shine} 3s linear infinite;
  }

  p {
    font-size: 1rem;
    color: ${Cores.textDark};
  }
`;

export const Creator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
  font-size: 0.9rem;
  color: ${Cores.textDark};

  a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease;
    padding: 8px 16px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      transform: translateY(-2px);
    }

    .dev-logo {
      border-radius: 50%;
      object-fit: cover;
    }

    .dev-title {
      font-size: 0.8rem;
      opacity: 0.8;
      margin-left: 8px;
      padding-left: 8px;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: 768px) {
    a {
      flex-direction: column;
      text-align: center;
      padding: 12px;
      
      .dev-title {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 8px;
        margin-top: 8px;
      }
    }
  }
`;
