import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

// Estilo da seção principal
export const StoreCss = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: ${Cores.primary};
  color: ${Cores.textDark};
`;

// Estilo do título da seção
export const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 2.5rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
  }

  p {
    font-size: 1.25rem;
    color: #666;
  }
`;

// Grid de itens
export const StoreGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: 0 auto;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 32px;
  }
`;

// Estilo de cada item do grid
export const StoreItem = styled.div`
  background: ${Cores.secondary};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:nth-child(2) {
    transform: translateY(-30px);
    
    video {
      height: 300px;
    }

    @media (max-width: 768px) {
      transform: translateY(0);
    }
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

  img,
  video {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  h3 {
    font-size: 1.3rem;
    margin: 16px;
    color: ${Cores.textDark};
  }

  p {
    font-size: 1rem;
    margin: 0 16px 16px;
    color: #666;
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    img, video {
      height: 200px;
    }

    &:nth-child(2) video {
      height: 200px;
    }

    h3 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;
