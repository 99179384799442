import { ReviewCard, ReviewsCss, ReviewsGrid, Title } from "./styles";
import { FaStar } from "react-icons/fa";

const reviews = [
  {
    name: "João Silva",
    rating: 5,
    date: "01/03/2024",
    comment:
      " Excelente atendimento! o Ricardo concertou meu celular no mesmo dia e o preço foi justo. Recomendo muito! ",
    service: "Troca de Tela",
    image: "/images/customer-joao.jpg",
  },
  {
    name: "Maria Santos",
    rating: 5,
    date: "10/04/2024",
    comment:
      " Profissional muito competente e atencioso. Resolveu o problema do meu iPhone rapidamente. ",
    service: "Reparo de Placa",
    image: "/images/customer-maria.jpg",
  },
  {
    name: "Ana Costa",
    rating: 5,
    date: "15/08/2024",
    comment:
      "Super recomendo! Conseguiram recuperar todos os dados do meu celular que tinha caído na água.",
    service: "Danos por Líquidos",
    image: "/images/customer-ana.jpg",
  },
];

const Reviews = () => (
  <ReviewsCss>
    <div className="container">
      <Title>
        <h2>Avaliações dos Clientes</h2>
        <p>Veja o que nossos clientes dizem sobre nossos serviços</p>
      </Title>

      <ReviewsGrid>
        {reviews.map((review, index) => (
          <ReviewCard key={index}>
            <div className="header">
              <img
                src={review.image}
                alt={`Foto de ${review.name}`}
                className="profile-image"
                loading="lazy" // Melhorando performance ao carregar imagens
              />
              <div className="profile-info">
                <h3>{review.name}</h3>
                <div
                  className="rating"
                  aria-label={`Nota: ${review.rating} de 5`}
                >
                  {[...Array(5)].map((_, i) => (
                    <FaStar
                      key={i}
                      color={i < review.rating ? "#FFD700" : "#E4E5E9"}
                      aria-hidden="true"
                    />
                  ))}
                </div>
              </div>
            </div>
            <span className="service">{review.service}</span>
            <p className="comment">{review.comment}</p>
            <span className="date">{review.date}</span>
          </ReviewCard>
        ))}
      </ReviewsGrid>
    </div>
  </ReviewsCss>
);

export default Reviews;
