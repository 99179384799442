import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";
import { pulse, shine } from "../../styles/animations";

export const ServiceCss = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: ${Cores.primary};
  color: ${Cores.textDark};
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 2.5rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
    background: linear-gradient(90deg, ${Cores.textDark}, #666, ${Cores.textDark});
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 3s linear infinite;
  }

  p {
    font-size: 1.25rem;
    color: #666;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin: 0 auto;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const ServiceCard = styled.div`
  background-color: ${Cores.secondary};
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

    .icon {
      animation: ${pulse} 2s infinite;
    }
  }

  .icon {
    font-size: 3rem;
    color: rgb(0, 123, 255);
    margin-bottom: 24px;
    transition: transform 0.3s ease;
  }

  h3 {
    font-size: 1.5rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 2px;
      background-color: ${Cores.primary};
      transition: width 0.3s ease;
    }
  }

  &:hover h3::after {
    width: 60px;
  }

  p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    padding: 24px;

    .icon {
      font-size: 2.5rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;
