import styled, { keyframes } from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

// Custom keyframe for sequential rising animation
const riseUp = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const HeroCss = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const Text = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  color: ${Cores.secondary};
  padding: 0 20px;

  h1 {
    font-size: clamp(2.5rem, 5vw, 5rem);
    font-style: italic;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  p {
    font-size: clamp(1.5rem, 3vw, 2rem);

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

export const SocialIcons = styled.div`
  position: absolute;
  bottom: 32%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1.5rem;
  z-index: 2;

  a {
    color: ${Cores.secondary};
    font-size: 2rem;
    transition: color 0.3s ease;

    &:nth-child(1) {
      animation: ${riseUp} 1.5s infinite;
    }

    &:nth-child(2) {
      animation: ${riseUp} 1.5s infinite;
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation: ${riseUp} 1.5s infinite;
      animation-delay: 1s;
    }

    &:hover {
      color: ${Cores.primary};
    }
  }
`;
