import { ReactNode, useEffect, useRef, useState } from "react";
import { AnimatedContainer } from "./styles";

interface AnimatedSectionProps {
  children: ReactNode;
  animation:
    | "fadeInUp"
    | "fadeInLeft"
    | "fadeInRight"
    | "zoomIn"
    | "pulse"
    | "float";
  delay?: number;
  threshold?: number;
  className?: string;
}

const AnimatedSection = ({
  children,
  animation,
  delay = 0,
  threshold = 0.2,
  className,
}: AnimatedSectionProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [threshold]);

  return (
    <AnimatedContainer
      ref={elementRef}
      $isVisible={isVisible}
      $animation={animation}
      $delay={delay}
      className={className}
    >
      {children}
    </AnimatedContainer>
  );
};

export default AnimatedSection; 