import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";
import { pulse, shine } from "../../styles/animations";

// Estilização principal da seção do formulário
export const ContactCss = styled.section`
  padding: 80px 0;
  background-color: ${Cores.primary};
`;

// Título da seção e descrição
export const Title = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h2 {
    font-size: 2.5rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
    background: linear-gradient(90deg, ${Cores.textDark}, #666, ${Cores.textDark});
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 3s linear infinite;
  }

  p {
    font-size: 1.25rem;
    color: #666;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

// Container do formulário
export const FormContainer = styled.form`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Seção do formulário com grupos de entrada
export const FormSection = styled.div`
  .input-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  select,
  input {
    padding: 0.8rem 1rem;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    background-color: ${Cores.secondary};
    transition: all 0.3s ease;
    font-size: 0.95rem;
    color: ${Cores.textDark};

    &:focus {
      border-color: ${Cores.primary};
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      outline: none;
    }

    &:disabled {
      background-color: #e9ecef;
      cursor: not-allowed;
    }
  }

  select {
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,...");
    background-position: right 1rem center;
    background-repeat: no-repeat;
  }
`;

// Descrição do problema (textarea)
export const ProblemDescription = styled.div`
  textarea {
    width: 100%;
    height: 93%;
    min-height: 200px;
    padding: 12px;
    background-color: ${Cores.secondary};
    border: 1px solid #ddd;
    border-radius: 12px;
    font-size: 1rem;
    resize: none;
    outline: none;
    transition: all 0.3s ease;
    color: ${Cores.textDark};

    &:focus {
      border-color: ${Cores.primary};
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
  }
`;

// Botão de envio
export const SubmitButton = styled.button`
  grid-column: 1 / -1; // Ocupa todas as colunas
  padding: 16px 32px;
  background-color: #25d366; // Verde padrão do WhatsApp
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(37, 211, 102, 0.3);

    &::before {
      left: 100%;
    }
  }

  p {
    font-size: 0.9rem;
    color: #fff;
    margin-top: 4px;
  }
`;
