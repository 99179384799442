import styled, { css } from "styled-components";
import {
  fadeInUp,
  fadeInLeft,
  fadeInRight,
  zoomIn,
  pulse,
  float,
} from "../../styles/animations";

interface AnimatedContainerProps {
  $isVisible: boolean;
  $animation:
    | "fadeInUp"
    | "fadeInLeft"
    | "fadeInRight"
    | "zoomIn"
    | "pulse"
    | "float";
  $delay: number;
}

export const AnimatedContainer = styled.div<AnimatedContainerProps>`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s;
  will-change: opacity, transform, visibility;
  transition-delay: ${({ $delay }) => $delay}s;

  ${({ $isVisible, $animation }) =>
    $isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      animation: ${getAnimation($animation)} 0.6s ease-out forwards;
    `}
`;

const getAnimation = (animation: string) => {
  switch (animation) {
    case "fadeInUp":
      return fadeInUp;
    case "fadeInLeft":
      return fadeInLeft;
    case "fadeInRight":
      return fadeInRight;
    case "zoomIn":
      return zoomIn;
    case "pulse":
      return pulse;
    case "float":
      return float;
    default:
      return fadeInUp;
  }
}; 