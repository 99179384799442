import { StoreCss, StoreGrid, StoreItem, Title } from "./styles";

// Dados dos itens da loja
const storeItems = [
  {
    type: "image",
    src: "images/store-front.jpg",
    title: "Nossa Loja",
  },
  {
    type: "video",
    src: "/videos/phone-repair.mp4",
    title: "Manutenção Profissional",
  },
  {
    type: "image",
    src: "/images/repair-equipment.jpg",
    title: "Equipamentos",
  },

];

const Store = () => (
  <StoreCss id="store">
    <div className="container">
      {/* Título da seção */}
      <Title>
        <h2>Nossa Loja</h2>
        <p>Conheça nosso espaço e estrutura</p>
      </Title>

      {/* Grid de itens */}
      <StoreGrid>
        {storeItems.map(({ type, src, title }, index) => (
          <StoreItem key={index}>
            {/* Renderização condicional de imagem ou vídeo */}
            {type === "image" ? (
              <img src={src} alt={title} loading="lazy" />
            ) : (
              <video
                autoPlay
                loop
                muted
                playsInline
                preload="metadata"
              >
                <source src={src} type="video/mp4" />
                Seu navegador não suporta vídeos.
              </video>
            )}
            <h3>{title}</h3>
          </StoreItem>
        ))}
      </StoreGrid>
    </div>
  </StoreCss>
);

export default Store;
