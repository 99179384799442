import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";
import { pulse, shine } from "../../styles/animations";

export const ReviewsCss = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: ${Cores.primary};
  color: ${Cores.textDark};
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 2.5rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
    background: linear-gradient(90deg, ${Cores.textDark}, #666, ${Cores.textDark});
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 3s linear infinite;
  }

  p {
    font-size: 1.25rem;
    color: #666;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const ReviewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px;
  margin: 0 auto;

  @media (max-width: 768px) {
    gap: 24px;
  }
`;

export const ReviewCard = styled.div`
  background-color: ${Cores.secondary};
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

    .profile-image {
      animation: ${pulse} 2s infinite;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    .profile-image {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
      border: 3px solid ${Cores.primary};
    }

    .profile-info {
      h3 {
        font-size: 1.25rem;
        color: ${Cores.textDark};
        margin-bottom: 8px;
      }

      .rating {
        display: flex;
        gap: 4px;
        color: #FFD700;
      }
    }
  }

  .service {
    display: inline-block;
    padding: 4px 12px;
    background-color: ${Cores.primary};
    color: ${Cores.textDark};
    border-radius: 16px;
    font-size: 0.875rem;
    margin-bottom: 16px;
  }

  .comment {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 16px;
    position: relative;
    
    &::before {
      content: '"';
      font-size: 3rem;
      color: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: -20px;
      left: -10px;
    }
  }

  .date {
    display: block;
    font-size: 0.875rem;
    color: #999;
    text-align: right;
  }

  @media (max-width: 768px) {
    padding: 20px;

    .header {
      .profile-image {
        width: 48px;
        height: 48px;
      }

      .profile-info h3 {
        font-size: 1.1rem;
      }
    }

    .comment {
      font-size: 0.9rem;
    }
  }
`;
